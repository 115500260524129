<template>
  <div class="row align-items-center justify-content-center text-center">
    <div class="col-12">
      <ElSpinner
        v-if="!errorMessage"
        color="primary"
      />
      <div
        v-else
        class="font-17 text-red"
      >
        {{ errorMessage }}
        <div class="mt-2">
          <router-link :to="{ name: 'auth.sign-in' }">
            Log In
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import Role from '@/constants/Role';
import { showSuccessNotify } from '@/utils/NotifyUtil';

export default {
  components: {},
  setup(props, context) {
    const errorMessage = ref(null);
    loginByToken();
    return {
      errorMessage,
    };

    async function loginByToken() {
      try {
        const user = await context.root.$store.dispatch('Auth/verifyAccountOwner', context.root.$route.params);
        const business = user.business ? user.business : false;
        const subscribeRoute = {
          name: 'app.subscribe',
        };

        const planName = context.root.$route.query?.plan_name;
        if (planName) {
          subscribeRoute.query = { plan_name: planName };
        }

        if (user.role === Role.ACCOUNT_OWNER && business && business.subscription === null) {
          context.root.$router.push(subscribeRoute);
          showSuccessNotify(context, 'Email verified successfully');
        } else if (context.root.$store.getters['Auth/subscription']) {
          context.root.$router.push({
            name: 'app.properties',
          });
        } else {
          context.root.$router.push(subscribeRoute);
        }
      } catch (error) {
        errorMessage.value = error.message;
      }
    }
  },
};
</script>
